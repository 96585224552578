.camada {
  height: 30px;
  width: 30px;
  top: 215px;
  left: 10px;
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  border-radius: 5px;
}

.camada-draw {
  height: 30px;
  width: 30px;
  top: 250px;
  left: 10px;
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  border-radius: 5px;
}

.opcoesMapa {
  background-color: #ffffff;
  margin-top: 10px;
  margin-right: 10px;
  position: relative;
  z-index: 1000;
  float: right;
  border: 1px solid #000000;
  border-radius: 5px;
}

.camada-hierarquia {
  padding-right: 50px;
  width: 300px;
  background-color: #ffffff;
  top: 90px;
  z-index: 1000;
  position: fixed;
}

.camada-hierarquia-draw {
  width: 300px;
  background-color: #ffffff;
  top: 125px;
  z-index: 1000;
  position: fixed;
}

.infoShape {
  background-color: #ffffff;
  position: fixed;
  z-index: 1001;
  float: right;
  height: 100%;
  right: 0;
  overflow: auto;
}

.infoShapeArrowRight {
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.infoShapeAcao {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* margin-top: 10px; */
}

.infoShapeAcaoIcon {
  margin-left: -6px;
  margin-top: -10px;
}

.infoShapeButton {
  background-color: #0080ce;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-top: 10px;
}

.infoShapeButton span {
  position: fixed;
  margin-top: -20px;
  margin-left: -7.5px;
  font-size: 25px;
}

.infoShapeIcon {
  margin-left: -5px;
  margin-top: -2px;
}

.infoShapeLabel {
  font-size: 12px;
}

.infoShapeRowButton {
  text-align: center;
}

.infoShapeButtonInformation {
  background-color: #0080ce;
  width: 100%;
  height: 30px;
  margin: 0px 5px 10px 5px;
}

.infoShapeButtonInformationLabel {
  margin-top: -3px;
  font-size: 14px;
  float: left;
}

.infoShapeButtonInformationIcon {
  float: right;
  margin-top: -3px;
}

.infoShapeTableAttributes td {
  font-size: 12px;
}

.infoShapeTableAttributes th {
  font-size: 12px;
}

.bottom10 {
  margin-bottom: 10px;
}

.bold {
  font-weight: bold;
}

.clusterStyleEmptyData {
  width: 40px;
  height: 40px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: #0080ce;
}

.clusterStyleData {
  width: 40px;
  height: 40px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: red;
}

.image-main {
  opacity: 1;
  display: block;
  width: 100%;
  height: 300px;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container-image-main:hover .image-main {
  opacity: 0.3;
}

.container-image-main:hover .middle {
  opacity: 1;
}

.colorGeneric {
  background-color: #0080d1;
}

.colorRed {
  background-color: #e57373;
}

.actions-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-bottom: 250px;
  cursor: pointer;
  margin-left: 10px;
}

.procurar {
  height: 30px;
  width: 30px;
  top: 255px;
  left: 10px;
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  border-radius: 5px;
}

.procurar-draw {
  height: 30px;
  width: 30px;
  top: 290px;
  left: 10px;
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  border-radius: 5px;
}

.container-procurar {
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  top: 100px;
  left: 50px;
  overflow-y: scroll;
}

.container-procura-titulo {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.container-procurar-itens {
  background-color: #eeeeee;
  border-width: 1px;
  border-style: solid;
  border-color: #cccccc;
  padding: 10px;
}

.container-procura-titulo-filtro {
  font-size: 12px;
  color: #0080ce;
  cursor: pointer;
}

.container-procurar-linha {
  border-top-width: 1px;
  border-top-color: #cccccc;
  border-top-style: solid;
}

.container-procurar-linha:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.filtros {
  height: 30px;
  width: 30px;
  top: 160px;
  left: -5px;
  background-color: #ffffff;
  position: relative;
  z-index: 1000;
  float: left;
  border-radius: 5px;
}

.filtros-draw {
  height: 30px;
  width: 30px;
  top: 25px;
  left: -5px;
  background-color: #ffffff;
  position: relative;
  z-index: 1000;
  float: left;
  border-radius: 5px;
}

.filtro-container {
  cursor: auto;
  background-color: #ffffff;
  top: 90px;
  height: 100vh;
  width: 500px;
  z-index: 1000;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  /* max-height: 900px;     */
}

.filtro-content {
  max-height: 830px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: 1000px) {
  .filtro-container {
    height: 90vh;
  }

  .filtro-col-button {
    margin-block: 1rem !important;
  }
}

.filtro-categoria {
  padding: 10px 10px 0px 10px;
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
}

.filtro-itens {
  font-size: 12px;
  margin-inline: 1px;
}

.filtro-form-group {
  display: flex;
  margin-bottom: 0.4rem !important;
}

.filtro-label {
  padding-top: 3px;
  padding-left: 5px;
  font-size: 0.7rem;
}

.filtro-col-button {
  margin-top: 1rem;
  margin-inline: 1.2rem;
  position: relative;
}

.filtro-button {
  display: flex;
  justify-content: center;
  align-self: flex-end;
  padding-inline: 60px;
}

.filtro-button-m2 {
  margin-bottom: 1rem;
}

.leaflet-control-graphicscale {
  background-color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 0.7;
}

.coordinates {
  height: 20px;
  width: 270px;
  background-color: #ffffff;
  opacity: 0.7;
  text-align: center;
}

.leaflet-control-browser-print {
  display: none;
}

.header-mapa-pdf {
  margin-top: 20px;
}

.header-mapa-pdf-title {
  margin-top: 20px;
  text-align: center;
}

.header-mapa-pdf-bottom {
  margin-bottom: 20px;
}

.legend {
  width: 270px;
  height: auto;
  background-color: #ffffff;
  opacity: 0.7;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.legend-img {
  width: 24px;
  height: 30px;
}

.neighbor-relatory {
  height: 30px;
  width: 30px;
  top: 335px;
  left: 10px;
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  border-radius: 5px;
}
