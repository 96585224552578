.containerForm {
    background-color: #eeeeee; 
    border: 1px solid #cccccc; 
    padding: 10px;
}

.title {
    margin-top: 10px; 
    margin-bottom: 10px;
    float: left;
}

.title h3 {
    font-weight: "normal"; 
    font-size: 25px;
}

.buttonClose {
    margin-top: 10px; 
    margin-bottom: 10px; 
    margin-right: 20px; 
    float: right;
}

.imageClose {
    cursor: pointer;
    width: 24px;
}

.buttonSave {
    float: right; 
    width: 150px;
}

.imageSave {
    margin-right: 20px; 
    cursor: pointer;
    margin-top: -5px;
    width: 16px;
}

.buttonCancel {
    float: right; 
    width: 150px;
}

.isRequired {
    color: red;
}

.bottom10 {
    margin-bottom: 10px;
}

.hide {
    display: none;
}

.header {
    margin-left: -5px; 
    margin-right: -5px; 
    background-color: #eeeeee;
    border-width: 1px; 
    border-style: solid; 
    border-color: #cccccc;
}

.infoFilter {
    margin-top: 10px;
}

.infoFilter h3 {
    font-size: 16px; 
    font-weight: normal; 
    color: #0080ce; 
    cursor: pointer;
}

.iconFilter {
    margin-right: 20px; 
    cursor: pointer;
    width: 16px;
}

.containerFilter {
    margin-top: 10px; 
    margin-bottom: 30px;
}

.btnFilter {
    background-color: #0080d1; 
    margin-top: 30px; 
    width: 150px;
}

.iconBtnFilter {
    margin-right: 20px; 
    cursor: pointer; 
    margin-top: -5px;
    width: 16px;
}

.containerActions {
    margin-top: 10px;
}

.colorGeneric {
    background-color: #0080d1;
}

.colorRed {
    background-color: #E57373;
}

.buttonGeneric {
    float: left; 
    margin-right: 5px;
    width: 150px;
    margin-bottom: 10px;
}

.iconButtonGeneric {
    margin-right: 20px; 
    cursor: pointer; 
    margin-top: -5px;
    width: 16px;
}

.tableData {
    margin-left: 10px; 
    margin-right: 10px;
    border-width: 1px;
    border-style: solid; 
    border-color: #cccccc; 
    background: linear-gradient(to bottom, #eeeeee 0%, #d7d7d7 100%)
}

.tableDataHead {
    background-color: #eeeeee; 
    font-size: 14px;
}

.tableDataBody {
    background-color: #ffffff; 
    font-size: 14px;
}

.tableContainerNav {
    margin-left: 10px; 
    margin-right: 10px; 
    margin-top: -20px; 
    border-width: 1px; 
    border-style: solid; 
    border-color: #cccccc; 
    background-color: #eeeeee;
}

.tableCountData {
    margin-left: 10px; 
    font-size: 12px; 
    font-weight: bold;
}

.tablePage {
    margin-left: 10px; 
    font-size: 12px;
}

.imageNav {
    margin-left: 10px; 
    cursor: pointer;
    width: 16px;
}

.mouseClick {
    cursor: pointer; 
}