
.leaflet-control.leaflet-compass {
	position:relative;
	color:#1978cf;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	/*background-color: rgba(0, 0, 0, 0.25);*/
	background-color: rgba(255, 255, 255, 0.8);
	/*background: none;*/
	box-shadow: 0 1px 7px rgba(0,0,0,0.65);
	margin-left:10px;
	margin-top:10px;
}
.leaflet-compass .compass-button {
	display:block;
	float:left;
	width:36px;
	height:36px;
	border-radius:4px;
}
.leaflet-compass .compass-button.active {
	background: #fff;
}
.leaflet-compass .compass-icon {
	height: 32px;
	width: 32px;
	margin: 2px;
	background: url('../Imagens/compass-icon.png') center center no-repeat;
}

.leaflet-compass .compass-digit {
}

.leaflet-compass .compass-alert {
	position:absolute;
	right:0;
	bottom:-30px;
	width:100px;
	padding:2px;
	line-height:.95em;
	color:#e00;
	border: 1px solid #888;	
	background-color: rgba(255, 255, 255, 0.75);
	border-radius:4px;
}
