.buttonSearch {
    margin-top: 30px;
    margin-left: -20px;
    text-align: left;
    background-color: #0080d1;
}

.buttonAdd {
    width: 20px;
    height: 20px;
    background-color: #0080d1;
    border-radius: 50%;
    color: #ffffff;
    margin-left: 5px;
    cursor: pointer;
}

.modal-full-screen {
    min-width: 90% !important;
    min-height: 90% !important;
    max-width: 90% !important;
    max-height: 90% !important;
    background-color: #ffffff;
    border-radius: 10px;
}

.fieldRequired {
    color: red
}