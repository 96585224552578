.item {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 20px;
}

.iconArrow {
    margin-top: -3px;
    width: 16px;
}

.iconExit {
    margin-right: 10px; 
    margin-top: -3px;
    width: 16px;
}

.infoContainer {
    background-color: #0080ce;
    width: 302px;
    height: 230px;
    margin-top: -90px; 
    margin-left: -55px;
}

.iconGeoEfficace {
    margin-left: 25px; 
    margin-top: 40px;
    width: 250px;
    border: 0px;
}

img {
    border: transparent;
}

.infoName {
    font-size: 14px; 
    margin-left: 20px; 
    margin-bottom: 0px; 
    margin-top: 30px; 
    color: #FFFFFF;
}

.navBackgroundColor {
    background-color: #0080ce;
}

.iconMenu {
    margin-left: 40px;
    width: 38px;
}

.react-confirm-alert-overlay {
    z-index: 2000 !important;
}