.containerForm {
    background-color: #eeeeee;
    border: 1px solid #cccccc;
    padding: 10px;
}

.containerFormNotBorder {
    background-color: #eeeeee;
    padding: 10px;
}

.title {
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
}

.title h3 {
    font-weight: "normal";
    font-size: 25px;
}

.buttonClose {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    float: right;
}

.imageClose {
    cursor: pointer;
    width: 24px;
}

.buttonSave {
    float: right;
    width: 150px;
}

.imageSave {
    margin-right: 20px;
    cursor: pointer;
    margin-top: -5px;
    width: 16px;
}

.buttonCancel {
    float: right;
    width: 150px;
}

.isRequired {
    color: red;
}

.bottom10 {
    margin-bottom: 10px;
}

.right10 {
    margin-right: 10px;
}

.top30 {
    margin-top: 30px;
}

.hide {
    display: none;
}

.fieldset {
    border: 1px solid #000000;
    padding: 6px;
    margin-bottom: 5px;
    width: 100%
}

.fieldset legend {
    font-size: 16px;
    width: auto;
    padding-right: 1px;
}

.buttonDelete {
    margin-right: 10px;
}

.header {
    margin-left: -5px;
    margin-right: -5px;
    background-color: #eeeeee;
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
}

.tab-item-header {
    color: #000000;
}

.tab-item-container {
    margin-top: 10px;
}

.tableData {
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
    background: linear-gradient(to bottom, #eeeeee 0%, #d7d7d7 100%)
}

.tableDataHead {
    background-color: #eeeeee;
    font-size: 14px;
}

.tableDataBody {
    background-color: #ffffff;
    font-size: 14px;
}

.containerActions {
    margin-top: 10px;
    margin-left: 10px;
}

.colorGeneric {
    background-color: #0080d1;
}

.colorRed {
    background-color: #E57373;
}

.tableContainerNav {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -20px;
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
    background-color: #eeeeee;
}

.addPerson {
    width: 20px;
    height: 20px;
    background-color: #0080d1;
    border-radius: 50%;
    color: #ffffff;
    margin-left: 5px;
    cursor: pointer;
}

.modal-full-screen {
    min-width: 90% !important;
    min-height: 90% !important;
    max-width: 90% !important;
    max-height: 90% !important;
    background-color: #ffffff;
    border-radius: 10px;
}

.left10 {
    margin-left: 10px;
}

.float-left {
    float: left;
}

.multi-select-list {
    display: flex;
    justify-content: space-between;
    line-height: 2rem;
    font-size: 0.8rem;
    font-weight: 100;
    max-height: 60px;
    overflow-y: auto;
    margin-inline: 0;
    background-color: rgba(85, 85, 85, 0.06);
}
    
/* Largura da barra de rolagem */
.smallScrollbal::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(85, 85, 85, 0.123);
    border-radius: 4px;
}

/* Estilo da barra de rolagem */
.smallScrollbal::-webkit-scrollbar-thumb {
    background-color: rgba(135, 135, 135, 0.522);
    border-radius: 4px;
    height: 30px;
}

/* Estilo da barra de rolagem quando estiver sendo arrastada */
.smallScrollbal::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Cor da barra de rolagem ao passar o mouse */
}